import { delay } from "../../common/utility/delay";
import { parseJwt } from "../../common/utility/jwt";
import {authGetDummyUserData} from "../../api/requests";
import {connectArgentWallet} from "../../common/starknetHelpers";

export const AuthManager = new authManager();

function authManager() {
    console.log("New: auth manager");

    let userAccountId = undefined;
    let token = undefined;
    let session = undefined;

    let authData = undefined;

    this.logout = () => {
        session = undefined;
        token = undefined;

        localStorage.removeItem('authToken');
        
        window.location.href = "/";
    }

//    this.getUserId = () => fpUserId;
    this.getAuthData = () => authData;
    this.getAuthToken = () => token;
    this.getSession = () => session;
    this.waitForSession = async () => {
        /*
        while (!session) {
            await delay(2000);
        }
        */

        return session;
    }

    function loadFromLocalStorage() {
        token = localStorage.getItem('authToken');
        userAccountId = localStorage.getItem('userAccountId');

        if (token && userAccountId)
            loadFromServer(userAccountId);
        else {
            token = undefined;
            userAccountId = undefined;
        }
    }

    async function loadFromServer(userId) {
        try {
            var response = await authGetDummyUserData({user_id: userId});
            token = response.auth_token;
            
            authData = {
                sid: response.sid,
                userId: response.user_id,
                wallet: response.wallet,
                starknetConfig: response.starknet_config
            }

            localStorage.setItem('authToken', token);
        } catch (error) {
            console.log("loadFromServer error: " + error);
        }
    }

    this.init = async function init() {
        loadFromLocalStorage();
    }

    this.localAuth = async function localAuth() {
        userAccountId = crypto.randomUUID();
        localStorage.setItem('userAccountId', userAccountId);
        loadFromServer(userAccountId);
    }

    this.argentAuth = async function argentAuth() {
        userAccountId = await connectArgentWallet();
        if (userAccountId) {
            localStorage.setItem('userAccountId', userAccountId);
            loadFromServer(userAccountId);
            return true;
        }
        return false;
    } 
}