import React from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from "@mui/material";
import { useDataFetcher } from "./utility/reactUtils";

const TitleValueTile = ({title, value, dataFetcher, hint}) => {

  const data = useDataFetcher(dataFetcher);
  
  if (value === undefined)  
    value = data;

  return (
     <div className="tile">
        {hint && (
        <Tooltip title={hint}>
            <HelpOutlineIcon className="hint-icon"/>
        </Tooltip>
        )}
        <div className="tile-title">{title}</div>
        {(value !== undefined) && <div className="tile-value">{value}</div>}
        {(value === undefined) && <div className="loading-skeleton" style={{margin: 5, width: "100%", height: "20%", alignSelf: "center"}}/>}
     </div>
  )
};

export default TitleValueTile;