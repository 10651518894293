import React from "react";
import { useDataFetcher } from "./utility/reactUtils";
import { Tooltip } from "@mui/material";
import { iconPath } from "./paths";

const IconTitleValue = ({icon, title, dataFetcher, hint, children}) => {

  const data = useDataFetcher(dataFetcher);
  
  let value = children;

  if (value === undefined)  
    value = data;

  return (
     <div className="iconTitleTile default-tile-header">
        {hint && (
        <Tooltip title={hint}>
            <img src={iconPath("question.svg")} className="hint-icon"/>
        </Tooltip>
        )}
        
        <div className="iconTitleTile-icon"> 
            <img src={iconPath(icon)}/>
        </div>
        <div className="iconTitleTile-texts">
            <div className="iconTitleTile-title">{title}</div>
            {(value !== undefined) && <div className="iconTitleTile-value">{value}</div>}
            {(value === undefined) && <div className="loading-skeleton" style={{margin: 5, width: "100%", height: "20%", alignSelf: "center"}}/>}
        </div>
     </div>
  )
};

export default IconTitleValue;