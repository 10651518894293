import TitleValueTile from "../../common/titleValueTile";
import React from "react";
import { Rewards } from "../managers/rewardsManager";
import { Link } from "react-router-dom";
import TextProvider from "../../common/textProvider";
import LeaderboardTable from "../tables/LeaderboardTable";
import IconTitleValue from "../../common/iconTitleValue";

const LeaderboardPage = () => {
    
    async function getRewardsCount() {
        let result = await Rewards.getRewardsInfoPromise();
        return result?.getRewardedPlayers();
    }

    async function getRandomRewardsCount() {
        let result = await Rewards.getRewardsInfoPromise();
        return result?.getRandomRewardsCount();
    }

    async function getRandomPointsRequired() {
        let result = await Rewards.getRewardsInfoPromise();
        return result?.getRandomRewardsThreshold();
    }
    
    async function getTotalPlayers() {
        while (Rewards.getKnownCurrentPlayers() === undefined)
            await new Promise(r => setTimeout(r, 1000));
        return Rewards.getKnownCurrentPlayers();
    }


    return (
        <>
        <h1>Season Info</h1>
        <div className="block-panel-main panel-row flex-gap-2 cool-list width100 leaderboard-info-panel">
            <div>
                <h3>At the end of the season, rewards will go to:</h3>
                <ul>
                    <li>Top-20 players according to their rating</li>
                    <li>10 random players</li>
                </ul>
                Please note that you need to earn at least<br/>20 points to have a chance to win a random reward
            </div>
            <div className="tile-grid">
                <IconTitleValue icon="people.svg" title={TextProvider.getText("tile::players")} dataFetcher={getTotalPlayers}
                    hint={TextProvider.getText("tile::players::hint")}/>
                <IconTitleValue icon="prizePeople.svg" title={TextProvider.getText("tile::places")} dataFetcher={getRewardsCount}
                    hint={TextProvider.getText("tile::places::hint")}/>
                <IconTitleValue icon="dice.svg" title={TextProvider.getText("tile::randomCount")} dataFetcher={getRandomRewardsCount}
                    hint={TextProvider.getText("tile::randomCount::hint")}/>
                <IconTitleValue icon="pointsMin.svg" title={TextProvider.getText("tile::randomPoints")} dataFetcher={getRandomPointsRequired}
                    hint={TextProvider.getText("tile::randomPoints::hint")}/>
            </div>

        </div>
        
        <div className="panel-row width100" style={{justifyContent: "space-between"}}> 
            <h1>Leaderboard</h1>
            <Link to="/leaderboard/previous" className="default-btn">View previous season</Link>
        </div>

        <LeaderboardTable pageSize={50} needPages={true}/>
        </>
    )
};

export default LeaderboardPage;