import React from "react";
import PaginatedList from "../../common/paginatedList";

const MapSeasonInfoBonusTable = ({bonusTable}) => {

    async function getPageData(page: int) {
        await new Promise(r => setTimeout(r, 1000));
        return {items: bonusTable, total: bonusTable.length};
    }

    return (
        <PaginatedList needPages={false} columnsList={columns} dataFetcher={getPageData} />
    )
};

const columns = [
    {
      Header: getHeaderElement("Score required"),
      accessor: "map_score",
      style: {"align-self": "center"},
      width: 30,
    },
    {
      Header: getHeaderElement("Bonus"),
      accessor: "grand_season_bonus",
      style: {"align-self": "center"},
      width: 30,
    },
  ];

function getHeaderElement(title) {
  return <div className="default-tile-header iconTitleTile-title">{title}</div>
}

export default MapSeasonInfoBonusTable;