import {
    apiGetGameReset,
    apiGetSeasonDiscordCode,
    apiGetSelfProfileData,
    apiGetWalletInfo,
    apiSetUsername
} from "../../api/requests";
import { Signal } from "../../common/utility/signal";
import { AuthManager } from "./authManager";

export const AccountInfoManager = new accountInfoManager();

function accountInfoManager() {
    console.log("New: accountInfoManager");

    let userData = undefined;
    let userDataPromise = undefined;

    let userSeasonDiscordCode = undefined;

    const userUpdatesSignal = new Signal();

    this.userUpdatesSignal = userUpdatesSignal;

    this.retrieveUserDataPromise = () => {
        if (userDataPromise)
            return userDataPromise;

        if (userData)
            return new Promise((resolve) => resolve(userData));

        refreshUserData();
        return userDataPromise;
    }

    function onUpdate(updateData) {

    }

    function refreshUserData() {
        userDataPromise = getUserDataPromise();
    }


    function getUserDataPromise() {
        return new Promise((resolve, reject) => {
            async function getUserData() {
                try {
                    const token = AuthManager.getAuthToken();
                    userData = await apiGetSelfProfileData(token);
                    userUpdatesSignal.fire();

                    resolve(userData);
                } catch (err) {
                    resolve(null);
                } finally {

                }
            }
            getUserData();
        });
    }

    this.refresh = () => {
        refreshUserData();
    }

    this.getUsername = () => {
        return userData?.user.username ?? "";
    }

    this.getUserSeasonDiscordCode = () => {
        return userSeasonDiscordCode?.data ?? "";
    }

    this.setNewNick = async(nickname) => {
        try {
            const token = AuthManager.getAuthToken();
            console.log(token)
            const res = await apiSetUsername(token, nickname);
            if (!res.error) {
                userData.user.username = nickname;
                userUpdatesSignal.fire();
                return true;
            }
            return false;
        } catch (err) {

        }
        return false;
    }

    this.gameReset = async() => {
        try {
            const token = AuthManager.getAuthToken();
            const res = await apiGetGameReset(token);
            return !res.error;

        } catch (err) {

        }
        return false;
    }

    async function getDiscordCode() {
        try {
            const token = AuthManager.getAuthToken();
            userSeasonDiscordCode = await apiGetSeasonDiscordCode(token);
            userUpdatesSignal.fire();

            
        } catch (err) {
            
        } finally {

        }
    }
} 