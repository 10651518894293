import React from "react";
import {Link} from "react-router-dom";
import { configuration } from "../../config";
import { iconPath } from "../../common/paths";

export function Footer() 
{
    return (
        <div className="footer">
            <span>Vova{mainColoredDot()} Vova{mainColoredDot()} Vova{mainColoredDot()}</span>
            
            <Link to="/tos" className="m-1">{getContent("terms of service")}</Link>
            <Link to="/terms" className="m-1">{getContent("privacy policy")}</Link> 
            <a href={configuration.twitter} target={"_blank"} className={"m-1"}>{getContent("twitter")}</a>
            <a href={configuration.discord} target={"_blank"} className={"m-1"}>{getContent("discord")}</a>
            <a href={configuration.contactUrl} target={"_blank"} className={"m-1"}>{getContent("contact us")}</a>
        </div>
    );
}

function getContent(text) {
    const upImg = <img src={iconPath("arrow-up.svg")}/>;

    return <span>{text} {upImg}</span>
}

function mainColoredDot() {
    return <span className="main-color">.</span>
}
