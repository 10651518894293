import { apiGetGlobalSeasonConfig } from "../../api/requests";
import { AsyncDict } from "../../common/utility/asyncDict";
import { CachedValue } from "../../common/utility/cachedValue";
import { AuthManager } from "./authManager";

export const SeasonManager = new seasonManager();

function seasonManager() {

    console.log("New: seasonManager");

    const seasonData = new CachedValue(apiGetGlobalSeasonConfig, 60000);

    this.getSeasonMaps = async () => {
        let data = await seasonData.get();

        if (!data || !data.success) 
            return [];

        return data.data.seasons.map(x => { return {
            mapId: x.config.map_id,
            /*
            name: x.name,
            image: x.image,
            start: x.date_start,
            end: x.date_end
            */
        }});       
    }

    this.getMapSeasonInfo = async (mapId) => {
        let data = await seasonData.get();

        if (!data || !data.success) 
            return undefined;
        
        var map = data.data.seasons.find(x => x.config.map_id == mapId);
        var r = map ? {
                name: map.config.name,
                placeScores: map.config.place_scores,
                pointsBonus: map.config.grand_season_scores
            } : null;
        return r;
    }

    this.getCurrentSeasonInfo = async () => {
        let data = await seasonData.get();

        if (!data || !data.success) 
            return undefined;

        return {
            dateEnd: data.data.grand_season.date_end,
            reward: data.data.grand_season.config.prize 
        }
    }
}