import { apiGetGlobalSeasonConfig, apiGetMapConfig } from "../../api/requests";
import { AsyncDict } from "../../common/utility/asyncDict";
import { CachedValue } from "../../common/utility/cachedValue";
import { AuthManager } from "./authManager";

export const MapsManager = new mapsManager();

function mapsManager() {
    console.log("New: mapsManager");

    const playInfo = new AsyncDict(fetchPlayInfo, 60000);

    const seasonData = new CachedValue(apiGetGlobalSeasonConfig, 60000);

    async function fetchPlayInfo(mapId) {
        const token = AuthManager.getAuthToken();
        if (!token)
            throw new Error("No auth");

        return await apiGetMapConfig(token, mapId);
    }

    this.getMapInfo = async (mapId) => {
        let data = await seasonData.get();

        if (!data || !data.success) 
            return [];

        var map = data.data.seasons.find(x => x.config.map_id == mapId);
        return map ? {
            name: map.config.name,
            mapId: map.config.map_id,
            image: map.config.image,
            description: map.config.description,
            dateStart: map.date_start,
            dateEnd: map.date_end
        } : null;
    }

    this.getMapPlayInfo = async (mapId) => {
        try {
            return await playInfo.get(mapId);
        } catch (err) {
            return null;
        }
    }

}