import { apiGetPrizeInfo, apiGetLeaderboard, apiGetUserInfoMany, apiGetPreviousSeasonInfo } from "../../api/requests";
import { configuration } from "../../config";

const chestTypes = ["gold", "silver", "bronze", "wooden"];

function getRewardsInfo(): Promise<RewardsInfo | null> {
    return new Promise( (resolve, reject) => {
        async function load() {
            try {
                let info = await apiGetPrizeInfo();
                resolve(new RewardsInfo(info));
            } catch (err) {
                resolve(null);
            }
        }
        load();
    });
}

function getPrevSeasonInfo(): Promise<PrevSeasonInfo | null> {
  return new Promise( (resolve, reject) => {
      async function load() {
          try {
              let info = await apiGetPreviousSeasonInfo();
              resolve(new PrevSeasonInfo(info));
          } catch (err) {
              resolve(null);
          }
      }
      load();
  });
}

function getRewardsArrayFromRewards(rewards) {
  return rewards.map(({amount, code}) => {
    const amountRounded = amount ? Number(amount.toFixed(3)) : undefined;
    const res = {
      amount: amountRounded,
      tooltip: `${amountRounded} ${code}`
    };
    switch (code) {
      
    }
    return res;
  });
}

class RewardsManager {

    currentPlayers = undefined;
    rewardsInfoPromise = undefined;
    previousSeasonInfoPromise = undefined;

    getRewardsInfoPromise(): Promise<RewardsInfo | null> {
        if (!this.rewardsInfoPromise) {
            this.rewardsInfoPromise = getRewardsInfo();
        }
        return this.rewardsInfoPromise;
    }

    getPrevSeasonInfoPromise(): Promise<PrevSeasonInfo | null> {
      if (!this.previousSeasonInfoPromise) {
          this.previousSeasonInfoPromise = getPrevSeasonInfo();
      }
      return this.previousSeasonInfoPromise;
    }

    getKnownCurrentPlayers(): int {
      return this.currentPlayers;
    }

    async getPageData(page, pageSize) {
        this.getRewardsInfoPromise();

        let players = [];
        let total = 0;
        try {
            const rewardsInfo = await this.rewardsInfoPromise;
            const leaderboard = await apiGetLeaderboard((page - 1) * pageSize, pageSize);
    
            if (leaderboard.leaderboard && leaderboard.leaderboard.users) {
                players = leaderboard.leaderboard.users;
                const ids = players.map(x => {return x.uid});
    
                const infos = await apiGetUserInfoMany(ids);
    
                players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid), reward: rewardsInfo.getReward(idx + 1, x.points) }});
                total = leaderboard.leaderboard.total;
                this.currentPlayers = total;
            } 
        } catch (err) {
            console.log("ERR", err);
        }
    
        return {items: players, total: total};
    }
}

export const Rewards = new RewardsManager();

class PrevSeasonInfo {
  
  dateStart = undefined;
  dateEnd = undefined;
  topCount = undefined;
  randomCount  = undefined;
  randomThreshold = undefined;
  playersCount = undefined;
  totalPoints = undefined;
  winners = undefined;
   
  constructor(info: Object) {
      info = info.data;
      this.dateStart = new Date(info.season.date_start * 1000);
      this.dateEnd = new Date(info.season.date_end * 1000);
      this.topCount = info.season.config.top_count;
      this.randomCount = info.season.config.random_count;
      this.randomThreshold = info.season.config.random_threshold;
      this.playersCount = info.season.total_users;
      this.totalPoints = info.season.config.total_exp;

      this.winners = info.winners;
  }

  async getPageData(page, pageSize) {
    let players = [];
    try {
            const ids = this.winners.map(x => {return x.user_id});
            const infos = await apiGetUserInfoMany(ids);
            players = this.winners.map((x, idx) => { return {
              place: x.place, 
              points: x.rating, 
              ...infos.users.find(y => y.uid === x.user_id), 
              reward: getRewardsArrayFromRewards([
                {amount: x.prize_stx, code:"STX"},
                {amount: x.prize_matic, code:"MATIC"},
              ].concat(
                chestTypes.map(c => { return {amount: x["chest_" + c], code: "chest_" + c}})
              ))}});
    } catch (err) {
        console.log("ERR", err);
    }

    return {items: players, total: this.winners.length};
  }

}

class RewardsInfo {
    constructor(info: Object) {
        this.info = info.info;
    }

    getRewardedPlayers(): int {
        return this.info.ranks.length;
    }

    getRandomReward() {
        return this.info.prize_stx * this.info.random_prize + " STX";
    }

    getTotalReward(): string {
        return this.formatRewards([
            {amount: this.info.prize_stx, code: "STX"}, 
            {amount: this.info.prize_matic, code: "MATIC"}
        ]);
    }

    getRewardForPlace(place: int) {
        const ranks = this.info.ranks;
        
        if (place >= 1 && place <= ranks.length) {
            const rewards = [
              {amount: this.info.prize_stx * ranks[place - 1].value, code: "STX"},
              {amount: this.info.prize_matic * ranks[place - 1].value, code: "MATIC"},
            ]

            return getRewardsArrayFromRewards(rewards);
        }
        return null;
    }

    getRandomRewardsCount(): int {
        return this.info.random_count;
    }

    getRandomRewardsThreshold(): int {
        return this.info.random_threshold;
    }

    getRandomRewardForPoints(points: int): string {
        if (points < this.info.random_threshold)
            return "";

        return this.getRandomReward();
    }

    getReward(place: int, points: int) {
        let reward = this.getRewardForPlace(place);
        if (reward)
            return reward;
        if (points >= this.info.random_threshold)
            return [{
                amount: 1,
                tooltip: `Chance to obtain ${this.getRandomReward()}`,
                image: require("../../static/lottery.png")
            }];
        return null;
    }

    getEndDate(): Date {
        return new Date(this.info.date_end);
    }

    formatRewards(rewards: Array): string {
        let result = "";
        for (let i = 0; i < rewards.length - 1; i++) {
            const reward = rewards[i];
            if (!reward.amount)
                continue;
            
            const amount = Number(reward.amount.toFixed(3));
            const code = reward.code;
            if (amount > 0) {
                if (result.length > 0)
                    result += "\n";
                result += `${amount} ${code}`;
            }
        }
        return result;
    }
}


