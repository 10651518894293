import React from 'react'

import MainPanel from "../elements/MainPanel";
import {MapsVerticalList} from "../elements/MapsList"

const MainPage = () => {
  return (
    <>
        <MainPanel/>
        <div className="mt-5">
              <MapsVerticalList/>
        </div>
    </>
  )
};

export default MainPage;