import React from "react";
import { columns } from "./MapLeaderboardTableColumns";
import PaginatedList from "../../common/paginatedList";
import { apiGetMapSeasonInfo, apiGetUserInfoMany } from "../../api/requests";

const MapLeaderboardTable = ({mapId, pageSize, needPages}) => {

    async function getPageData(page: int) {
        const res = await loadMapTop(mapId, page, pageSize);
        await new Promise(r => setTimeout(r, 1000));
        return res;
    }

    return (
        <PaginatedList rowCount={pageSize} needPages={needPages} columnsList={columns} dataFetcher={getPageData} 
        //emptyElement={<div><h1>Fetching data...</h1></div>}
        />
    )
};

async function loadMapTop(mapId, page, pageSize) {
    try {
        var response = await apiGetMapSeasonInfo(mapId, (page - 1) * pageSize, pageSize);
        
        let players = response.leaderboard.users;
        const ids = players.map(x => {return x.uid});

        const infos = await apiGetUserInfoMany(ids);
        players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid) }});

        return {items: players, total: response.leaderboard.total};
    } catch (error) {
        return {items: [], total: 0};
    }
}
export default MapLeaderboardTable;