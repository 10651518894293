import { useTable, usePagination, useFlexLayout, useBlockLayout } from "react-table";
import * as React from 'react';
import Paginator from './paginator.js';


function PaginatedList({rowCount, needPages, emptyElement, columnsList, dataFetcher}) {
  const columns = React.useMemo(() => columnsList, []);
    
  const [isLoading, setIsLoading] = React.useState(false);
  const [itemsInfo, setItemsInfo] = React.useState({items: [], total: -1});
  
  const fetchIdRef = React.useRef(0);
  
  const data = React.useMemo(() => itemsInfo.items, [itemsInfo]);
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, gotoPage, page, state: { pageIndex } } =
    useTable(
    {
      columns, data,
      initialState: { pageIndex: 1, pageSize: rowCount },
      manualPagination: true, 
      pageCount: -1,
    },
    usePagination,
	useFlexLayout
  )
  
  function handlePageChange(newPage: int) {
	  console.log("NEW PAGE:" + newPage);
	  gotoPage(newPage);
  }

  React.useEffect(() => {
	  // TODO take a look at: useAsyncDebounce(onFetchData, 100)

	  const fetchId = ++fetchIdRef.current;
	  
	  let isActive = true;
	  
	  load();
	  
	  return () => { isActive = false }; // cleanup function

	  async function load() {
		setIsLoading(true);
		const res = await dataFetcher(pageIndex);
		
		if (!isActive)
			return;
		
		if (fetchId != fetchIdRef.current)
			return;
		
		setIsLoading(false);
		setItemsInfo(res);
	  }
	}, [pageIndex]);
  
  const tableIsFull = itemsInfo.items.length == rowCount;
  const showEmptyElement = emptyElement && !isLoading && pageIndex == 1 && itemsInfo.items.length == 0;
  const showPagination = needPages && !showEmptyElement && !isLoading && (tableIsFull || pageIndex != 1);

  const pages = itemsInfo.total > 0 ? itemsInfo.total / rowCount : -1;
  return (
	<div className="table-container">
		{!showEmptyElement && 
		<>
			<table {...getTableProps()} className="leaderboard-table">
			<thead>
				{headerGroups.map((headerGroup) => (
				<tr {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map((column) => (
					<th {...column.getHeaderProps()}>
						{column.render("Header")}
					</th>
					))}
				</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
				prepareRow(row);
				return (
					<tr {...row.getRowProps()}>
					{row.cells.map((cell) => (
						<td {...cell.getCellProps({style: cell.column.style})}> {cell.render("Cell")} </td>
					))}
					</tr>
				);
				})}
			</tbody>
			</table>
		</>}
		{showEmptyElement && {...emptyElement}}
		{showPagination && <Paginator startPage={pageIndex} pages={pages} handleChange={handlePageChange} tableIsFull={tableIsFull}/>}
		{isLoading && <div className="overlay">
			<div className="centered">
				<img src={require("../static/loading.png")} width="128" height="64" className="animRotateY"/>
			</div>
		</div>}
	</div>
  );
}

export default PaginatedList; 