import { formatDurationMs } from "./utility/dateUtils";
import { useCountdown } from "./utility/useCountdown";

const CountdownLabel = ({ targetDate }) => {
    const time = useCountdown(targetDate);
  
    return formatDurationMs(time);
  };

export default CountdownLabel;
