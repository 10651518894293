import React from "react";
import { Link } from "react-router-dom";
import TitleValueTile from "../../common/titleValueTile";
import { Loading } from "../../common/utility/loading";
import AccountSignInForm from "../popups/AccountSignInForm";
import { AuthManager } from "../managers/authManager";
import { MapsManager } from "../managers/mapsManager";
import IconTitleValue from "../../common/iconTitleValue";
import { formatDateTimeFromUnixTime, formatDurationS } from "../../common/utility/dateUtils";
import CountdownLabel from "../../common/countdownLabel";
import MapSeasonInfoPopup from "../popups/MapSeasonInfoPopup";


function MapInfoElement({mapId}) {
    
    const [map, setMapData] = React.useState(undefined);

    const token = AuthManager.getAuthToken();

    async function load() {
        setMapData(await MapsManager.getMapInfo(mapId));
    }

    function cleanup() {}
    React.useEffect(()=>{ load(); return cleanup; }, []);

    if (map === undefined)
    {
        return <Loading loading_text="Preparing map..." />
    }

    if (map === null) {
        return (
            <div className="d-flex flex-column align-items-center p-5">
                <div>
                    Something went wrong :(
                </div>
            </div>
        )
    }

    let dateStart = formatDateTimeFromUnixTime(map.dateStart);
    let duration = map.dateEnd - map.dateStart;
    let started = new Date(map.dateStart * 1000) < new Date();

    let playBtn = (!token ? <AccountSignInForm label="Play" /> : 
        (map ? (<Link to={`/game/${mapId}/play`} className="default-btn">Play</Link>) : (<></>)));

    let timeElements = (started ? 
    <IconTitleValue title="Ends in" icon="time.svg"><CountdownLabel targetDate={map.dateEnd * 1000}/></IconTitleValue> :
    <> 
        <IconTitleValue title="Starts at" icon="time.svg">{dateStart.toString()}</IconTitleValue>
        <IconTitleValue title="Duration" icon="timeTotal.svg">{formatDurationS(duration)}</IconTitleValue>
    </>);

    return (
        <div className="game-info-main-panel">
            <div className="game-info-left-part">
                <div className={"card game-view-card"} >
                    <img className={"card-img-top"} src={map.image} alt={map.name} />
                    <div className={"card-body"}>
                        <h3 className="card-title">{map.name}</h3>
                        <center>{playBtn}</center>
                        
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column block-panel-main cool-list game-info-right-part">
                <div className="tile-container">
                    {timeElements}
                    <Link to={`/map/${mapId}/top`} className="default-btn">View map top</Link>
                    <MapSeasonInfoPopup mapId={mapId}/>
                </div>
                <p className="card-text game-description-text white">{map.description.split('\n').map(function(item, key) {
                    return (<span key={key}>{item}<br/></span>)
                })
                }</p>
            </div>
            
        </div>
    )
}


export default MapInfoElement;
