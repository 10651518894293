import React from 'react'

import {Link, useParams} from "react-router-dom";
import MapInfoElement from '../elements/MapInfoElement';

export function MapInfoPage() {

    const params = useParams();
    const map = params.map;

    return (
        <div className="mt-3">
            <MapInfoElement mapId={map}/>
        </div> 
    )
}

export default MapInfoPage;