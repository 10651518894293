import { ec, stark, hash, CallData } from "starknet";
import { connect, disconnect } from "starknetkit"
import { ArgentMobileConnector } from "starknetkit/argentMobile";

export async function connectArgentWallet() {
	var appName = "FORCE PRIME HEROES";

	const starknet = await connect({
		dappName: appName,
		argentMobileOptions: new ArgentMobileConnector({ dappName: "appName" })
	});

	if (!starknet) {
		throw Error("User rejected wallet selection or silent connect found nothing")
	}

	await (await starknet).enable();

	return starknet.account.address;
}