import Modal from 'react-bootstrap/Modal';
import React, {useState} from "react";
import { SeasonManager } from '../managers/seasonsManager';
import MapSeasonInfoBonusTable from '../tables/MapSeasonInfoBonusTable';
import { SingleAccordionDef } from '../../common/SingleAccordion';
import MapSeasonInfoPlaceTable from '../tables/MapSeasonInfoPlaceTable';

function MapSeasonInfoPopup({mapId}) {
    const [map, setMapData] = React.useState(undefined);

    async function load() {
        setMapData(await SeasonManager.getMapSeasonInfo(mapId));
    }

    function cleanup() {}
    React.useEffect(()=>{ load(); return cleanup; }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => { load(); setShow(true); }

    return (
        <>
            <button className={"default-btn upper-menu-btn"} onClick={handleShow}>
                View Info
            </button>
            {map ? (
            <Modal show={show} onHide={handleClose} dialogClassName="fp-modal-form">
                <Modal.Body>
                    <div>
                        <h1>{map.name}</h1>
                        <div className="panel-column gap-1 profile-page-tables-container">
                            <div className="profile-page-table-div block-panel-main">
                                <SingleAccordionDef header="Season points for score">
                                    <MapSeasonInfoBonusTable bonusTable={map.pointsBonus}/>
                                </SingleAccordionDef>
                            </div>

                            <div className="profile-page-table-div block-panel-main">
                                <SingleAccordionDef header="Season points for place">
                                    <MapSeasonInfoPlaceTable placeTable={map.placeScores}/>
                                </SingleAccordionDef>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>) : <></>}
        </>
    );
}

export default MapSeasonInfoPopup; 