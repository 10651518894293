import React from "react";

class OppsPage404 extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div className={"d-flex flex-column flex-wrap align-items-center"}>
                <div className={"p-3 mt-3 rounded-lg login-item mr-auto ml-auto"}>
                    <div className={"mt-3"}>
                        <h3>Page not found :(</h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default OppsPage404;
