import React from 'react'

const MapsPage = () => {
  return (
    <>
    <h1> All maps page </h1>
    <div className="d-flex flex-column user-box">
        TODO:
        all maps available to play 
    </div>
    </>
  )
};

export default MapsPage;

