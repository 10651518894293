import React from 'react'

import {Loading} from "../../common/utility/loading";

import {useParams} from "react-router-dom";
import { AuthManager } from '../managers/authManager';
import { MapsManager } from '../managers/mapsManager';

import './PlayGamePage.css';

function PlayGamePage() {

    const params = useParams();
    const gameId = params.game;
    const token = AuthManager.getAuthToken();

    const [playData, setPlayData] = React.useState(undefined);

    async function load() {
        const playDataRes = await MapsManager.getMapPlayInfo(gameId);
        setPlayData(playDataRes);
    }

    function cleanup() {}
    React.useEffect(()=>{ load(); return cleanup; }, []);

    if (!token) {
        return <center><h1> Please Sign In </h1></center>
    }

    if (!playData)
    {
        return <Loading loading_text={"preparing game"} />
    }

    let authData = AuthManager.getAuthData();
    let mapConfig = playData.map_config;

    let iframeUrl = mapConfig.webgl_url +
        "?private_key=" + authData.wallet.privateKey +
        "&public_key=" + authData.wallet.publicKey +
        "&account=" + authData.wallet.address +
        "&access_token=" + String(process.env.REACT_APP_PUBLIC_KEY) +
        "&sid=" + authData.sid +
        "&uid=" + authData.user_id +
        "&contract=" + mapConfig.contract;

    return (
        <>
            <iframe
                    src={iframeUrl}
                    name="game"
                    id="game_iframe"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen={true}
                    allow="autoplay;"
                    frameBorder="0"
                    scrolling="no"
            /> 
        </>
    );
}

export default PlayGamePage;