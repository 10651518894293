import CoolLink from '../../common/coolLink';
import { configuration } from '../../config';


const LeftPanel = () => {

  return (
    <div className="left-panel-main">
      <CoolLink linkTo="/leaderboard" text="season" icon="top.svg"/>
      <CoolLink linkTo="/maps" text="maps" icon="games.svg"/>
      <CoolLink linkTo="/tutorial" text="tutorial" icon="games.svg"/>
      <CoolLink linkTo={configuration.discord} text="discord" icon="discord.png"/>
      <CoolLink linkTo={configuration.twitter} text="twitter" icon="twitter.svg"/>
      <CoolLink linkTo={configuration.docs} text="docs" icon="docs.svg"/>
    </div>
 )
};

export default LeftPanel;