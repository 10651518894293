import { Accordion, useAccordionButton } from "react-bootstrap";

function SingleAccordion({header, children}) {
    return (
    <Accordion>
        <AHeader eventKey="0" element={header}/>
        <Accordion.Collapse eventKey="0">
            {children}
        </Accordion.Collapse>
    </Accordion>);
}

function AHeader({eventKey, element}) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return <div onClick={decoratedOnClick}>{element}</div>
}

export function SingleAccordionDef({header, children}) {
    return (
    <SingleAccordion header={<h3 className="dropdown-toggle main-color pointer">{header}</h3>}>
        {children}
    </SingleAccordion>);
}

export default SingleAccordion;