import { Outlet, Link } from "react-router-dom";

import {AccountPanel} from "./elements/AccountPanel";
import {Footer} from "./elements/Footer";
import LeftPanel from "./elements/LeftPanel";

const CommonLayout = () => {
  return (
    <>
	  <div className="background" />
	  <LeftPanel/>
	  <AccountPanel/>
	  <div className="container-main">
		 <Outlet/>
		 <Footer />	
	  </div>
	  
    </>
  )
};

export default CommonLayout;