export class CachedValue {
    constructor (dataFetcher, refreshTimeout) {
        this.data = undefined;
        this.dataFetcher = dataFetcher;
        this.refreshTimeout = refreshTimeout;
        this.lastUpdate = 0;
    }

    async get() {
        const currentTime = new Date();
        if (this.lastUpdate == 0 || currentTime - this.lastUpdate > this.refreshTimeout) {
            const promise = this.dataFetcher();
            promise.then(x => {this.data = x; this.lastUpdate = new Date();});
            return promise;
        } else {
            return this.data;
        }
    }
}
