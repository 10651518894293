const defaultData = {
    "tile::points": "Available Points",
    "tile::points::hint": "Points that can be earned before the end of the season",
    "tile::players": "Active Players",
    "tile::players::hint": "Number of players participating in the current season",
    "tile::places": "Places Rewarded",
    "tile::places::hint": "The first places will receive guaranteed rewards",
    "tile::randomCount": "Random Rewards",
    "tile::randomCount::hint": "Players who did not make it to the top will take part in the random givaway if they have enough points",
    "tile::randomPoints": "Points Minimum",
    "tile::randomPoints::hint": "Minimum number of points to participate in the random reward giveway",
}

const TextProvider = function () {
    const data = {};

    addStrings(defaultData);

    function getText(key) {
        return data[key];
    }

    function addStrings(strings) {
        Object.assign(data, strings)
    }

    return {getText};   
}();

export default TextProvider;