import { Link } from "react-router-dom";
import React from "react";
import LeaderboardTable from "../tables/LeaderboardTable";
import NewsElement from "./NewsElement";

const MainPanel = () => {
  return (
    <> 
        <NewsElement/>
        <h1>Play<span className="main-color">.</span> Enjoy<span className="main-color">.</span> Earn<span className="main-color">.</span></h1>

        <div className="main-panel flex-panel">
            <div className="main-panel-description-part">
                <div className="block-panel-main cool-list">
                    <h3>Welcome to the community owned <span className="main-color font-heavy">GameFi</span> platform.</h3>
                    <span className="main-color">Force Prime</span> is now in alpha. Testing the code, spinning the gears. We will be adding games to the platform step by step, testing various balance settings.
                    But you can already <span className="main-color">win prizes</span>.
                    <ul>
                    <li>Complete tasks in games to earn Points</li>
                    <li>Take the top places and get a reward at the end of the week</li>
                    <li>Guaranteed Rewards Fund is constantly growing</li>
                    </ul>
                    <br/>
                    At the moment, the platform is managed more centrally. But gradually we will transfer control to our community. Join Discord so you don't miss out!
                </div>
            </div>

            <div className="main-panel-table-part block-panel-main">
                <LeaderboardTable pageSize={4} needPages={false}/>
                <Link 
                    className="default-btn main-panel-view-button"
                    role="button"
                    to="/leaderboard"
                    > 
                    View All
                </Link>
            </div>
        </div>
     </>
  )
};

export default MainPanel;