import React from 'react'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap/dist/css/bootstrap.css';

import {Route, Routes} from "react-router-dom";
import {AccountProfilePage} from "./pages/AccountProfilePage";

import CommonLayout from "./CommonLayout.js";

import TermsPage from "./pages/TermsPage";
import TosPage from "./pages/TosPage";
import DisclaimerPage from "./pages/DisclaimerPage";

import {toast} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import LeaderboardPage from './pages/LeaderboardPage';
import OppsPage404 from './pages/OppsPage404';

import MainPage from './pages/MainPage';
import MapInfoPage from './pages/MapInfoPage.js';
import PrevSeasonResultPage from './pages/PreviousSeasonPage';
import PlayGamePage from './pages/PlayGamePage';
import { AuthManager } from './managers/authManager.js';
import { delay } from '../common/utility/delay.js';
import TutorialPage from './pages/TutorialPage.js';
import MapsPage from './pages/MapsPage.js';
import MapTopPage from './pages/MapTopPage.js';

class Forceprime extends React.Component {
    constructor(props) {
        super(props);

        window.alerts = {
            success: (text) => this.notifySuccess(text),
            alert: (text) => this.notifyAlert(text)
        }

        window.loginFlow = {
            startAnon: () => this.startAnonLogin(),
            startWallet: () => this.startWalletLogin()
        }
        //window.fpUpdateListener.registerCallback(this.onUpdate.bind(this));
    }

    async onUpdate(updateData) {
        if (updateData.type == "update_exp_points")
            this.notifySuccess(`Wow! ${updateData.exp_points} points earned!`);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    async startAnonLogin() {
        await AuthManager.localAuth();
        await delay(500);
        this.notifySuccess("Authenticated successfully!");
        await delay(500);
        window.location.reload();
    }

    async startWalletLogin() {
        var connected = await AuthManager.argentAuth();
        if (connected) {
            await delay(500);
            this.notifySuccess("Wallet connected successfully!");
            await delay(500);
            window.location.reload();
        }
    }

    async notifyAlert(text) {
        toast.error(text, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async notifySuccess(text) {
        toast.success(text, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    render() {
        return (
            <>
                <Routes>
					    <Route path="/" element={<CommonLayout/>}>
					  
                        <Route index path="/"  element={<MainPage/>} />
						
                        <Route path="/profile"  element={ <AccountProfilePage/>} />

                        <Route path="/tutorial"  element={ <TutorialPage/>} />

                        <Route path="/maps"  element={ <MapsPage/>} />

                        <Route path="/leaderboard" element={<LeaderboardPage/>} />

                        <Route path="/leaderboard/previous" element={<PrevSeasonResultPage/>} />

                        <Route path="/terms" element={<TermsPage />} />

                        <Route path="/tos"  element={<TosPage />} />

                        <Route path="/disclaimer"  element={<DisclaimerPage />} />

                        <Route path="/game/:game/play"  element={<PlayGamePage/>}/>

                        <Route path="/game/:game"  element={<MapInfoPage/>} />

                        <Route path="/map/:map/top"  element={<MapTopPage/>} />

                        <Route path='*' element={<OppsPage404 />}/>

				     </Route>
                   </Routes>
            </>
        );
    }
}

export default Forceprime;
