import React from "react";
import MapInfoElement from "./MapInfoElement";
import "./../../games.css";
import { SeasonManager } from "../managers/seasonsManager";
import { AuthManager } from "../managers/authManager";

export function MapsVerticalList() {
    const [mapsList, setMapsList] = React.useState(undefined);

    const token = AuthManager.getAuthToken();

    async function load() {
        const maps = await SeasonManager.getSeasonMaps();
        setMapsList(maps);
    }

    function cleanup() {}
    React.useEffect(()=>{ load(); return cleanup; }, []);

    return (
    <>
        <h1 id="playgames">Season challenges</h1>
        <div className="game-info-main-panel pb-5">
        {mapsList ?
            (mapsList.map((map, idx) => 
                            <MapInfoElement key={idx} mapId={map.mapId}/>)) : (<></>)
        }
        </div>
    </>)
}

