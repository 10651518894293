import React from "react";
import { OverlayTrigger } from "react-bootstrap";

const RewardList = ({rewards, maxlen}) => {
  maxlen = maxlen || 3;

  if (!rewards || rewards.length === 0)
    return (<></>);

  rewards = rewards.filter(item => item.amount);

  const renderTooltip = (props) => (
    <div className="rewards-list-tooltip-container" {...props}>
        <h4>Rewards</h4>

         {rewards.map((item, key) => {return item.amount && 
            (<div className="panel-row gap-1" key={key}><img src={item.image}/>{item.tooltip}</div>)})}
    </div>
  );
  
  const items = rewards.slice();

  if (items.length > 3) {
    items.length = 2;
    items.push({amount: 1, image: require("../../static/more-icon.png")});
  }
  
  return (
    <>
      <OverlayTrigger 
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}>
        <div className="reward-list panel-row">
          {items.map((item, key) => 
            (<div style={{display:"contents"}} key={key}> {item.amount > 1 ? item.amount : ""} <img src={item.image}/></div>))
          }
        </div>
      </OverlayTrigger>
    </>
  )
};

export default RewardList;