function p(value) {
    return String(value).padStart(2, '0');
}

export function formatDurationMs(durationMs) {
    const [days, hours, minutes, seconds] = extractValuesFromMs(durationMs);

    if (days + hours + minutes + seconds <= 0) {
        return ("00:00:00")
    } else {
        const time = `${p(hours)}:${p(minutes)}:${p(seconds)}`;
        return days > 0 ? `${days}d ${time}` : time;  
    }
}

export function formatDurationS(s) { return formatDurationMs(s * 1000); }

export function extractValuesFromMs(ms) {
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export function formatDateTimeFromUnixTime(unixTime) {
    var d = new Date(unixTime * 1000);
    return d.toLocaleString();
}