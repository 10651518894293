import React from "react";
import PaginatedList from "../../common/paginatedList";

const MapSeasonInfoPlaceTable = ({placeTable}) => {

    async function getPageData(page: int) {
        await new Promise(r => setTimeout(r, 1000));
        return {items: placeTable, total: placeTable.length};
    }

    return (
        <PaginatedList needPages={false} columnsList={columns} dataFetcher={getPageData} />
    )
};

const columns = [
    {
      Header: getHeaderElement("Place required"),
      accessor: "place",
      style: {"align-self": "center"},
      width: 30,
    },
    {
      Header: getHeaderElement("Points"),
      accessor: "score",
      style: {"align-self": "center"},
      width: 30,
    },
  ];

function getHeaderElement(title) {
  return <div className="default-tile-header iconTitleTile-title">{title}</div>
}

export default MapSeasonInfoPlaceTable;