import Modal from 'react-bootstrap/Modal';
import {useState} from "react";

function AccountSignInForm({label}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onClickWalletBtn = (data, e) => { setShow(false); window.loginFlow.startWallet(); };
    const onClickAnonBtn = (errors, e) => { setShow(false); window.loginFlow.startAnon() };

    return (
        <>
            <button className={"upper-menu-btn default-btn"} onClick={handleShow}>
                {label}
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="fp-modal-form">
                <Modal.Body>
                    <div className="d-flex flex-column user-box">
                        <h1 className={"text-center"}>Connect</h1>
                            <div className={"p-5 d-flex flex-column align-items-center"}>
                                <button className={"default-btn w-100"} onClick={onClickWalletBtn}>connect wallet</button>
                                <button className={"m-1 default-btn w-100"} onClick={onClickAnonBtn}>anonymous login</button>
                            </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AccountSignInForm;
