import React from "react";
import { useDataFetcher } from "./utility/reactUtils";

const TitleValueLine = ({title, dataFetcher, children}) => {

  const data = useDataFetcher(dataFetcher);
  
  let value = children;
  if (value === undefined)  
    value = data;

  return (
     <div className="titleValueLine">
        <div className="titleValueLine-title">{title}</div>
        {(value !== undefined) && <div className="iconTitleTile-value ">{value}</div>}
        {(value === undefined) && <div className="loading-skeleton" style={{margin: 5, width: "100%", height: "20%", alignSelf: "center"}}/>}
     </div>
  )
};

export default TitleValueLine;