import React from "react";
import { columns } from "./LeaderboardTableColumns";
import { Rewards } from "../managers/rewardsManager";
import PaginatedList from "../../common/paginatedList";

const LeaderboardTable = ({pageSize, needPages}) => {

    async function getPageData(page: int) {
        const res = await Rewards.getPageData(page, pageSize);
        await new Promise(r => setTimeout(r, 1000));
        return res;
    }

    return (
        <PaginatedList rowCount={pageSize} needPages={needPages} columnsList={columns} dataFetcher={getPageData} 
        //emptyElement={<div><h1>Fetching data...</h1></div>}
        />
    )
};

export default LeaderboardTable;