import PaginatedList from "../../common/paginatedList";
import { columns } from "../tables/LeaderboardTableColumns";
import React from "react";
import { Rewards } from "../managers/rewardsManager";
import TextProvider from "../../common/textProvider";
import TitleValueTile from "../../common/titleValueTile";

const PrevSeasonResultPage = () => {
    
    const promise = Rewards.getPrevSeasonInfoPromise();

    async function getPageData(page: int) {
        const prevSeason = await promise;

        const res = await prevSeason.getPageData(page, 50);
        await new Promise(r => setTimeout(r, 1000));
        return res;
    }

    async function getRewardsCount() {
        let result = await promise;
        return result.topCount
    }

    async function getRandomRewardsCount() {
        let result = await promise;
        return result.randomCount;
    }

    async function getRandomPointsRequired() {
        let result = await promise;
        return result.randomThreshold;
    }
    
    async function getTotalPlayers() {
        let result = await promise;
        return result.playersCount;
    }

    async function getPoints() {
        let result = await promise;
        return result.totalPoints;
    }

    return (
        <>
        <h3 className="block-title-main mt-4">Previous Season Info</h3>
        <div className="panel-row flex-gap-1 flex-wrap">
            <TitleValueTile title={TextProvider.getText("tile::points")} dataFetcher={getPoints}
                hint={TextProvider.getText("tile::points::hint")}/>
            <TitleValueTile title={TextProvider.getText("tile::players")} dataFetcher={getTotalPlayers}
                hint={TextProvider.getText("tile::players::hint")}/>
            <TitleValueTile title={TextProvider.getText("tile::places")} dataFetcher={getRewardsCount}
                hint={TextProvider.getText("tile::places::hint")}/>
            <TitleValueTile title={TextProvider.getText("tile::randomCount")} dataFetcher={getRandomRewardsCount}
                hint={TextProvider.getText("tile::randomCount::hint")}/>
            <TitleValueTile title={TextProvider.getText("tile::randomPoints")} dataFetcher={getRandomPointsRequired}
                hint={TextProvider.getText("tile::randomPoints::hint")}/>
        </div>
        <h3 className="block-title-main mt-4">Previous Season Results</h3>
        <PaginatedList rowCount={50} needPages={false} columnsList={columns} dataFetcher={getPageData} />
        </>
    )
};

export default PrevSeasonResultPage;