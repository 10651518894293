import React from 'react'

import EditableLabel from '../../common/editableLabel';
import { AccountInfoManager } from '../managers/accountInfoManager';
import { Rewards } from '../managers/rewardsManager';
import RewardList from '../elements/RewardList';
import TitleValueLine from '../../common/titleValueLine';

export function AccountProfilePage() {
    const alert = window.alerts?.alert;
    const success = window.alerts?.success;

    const forceUpdate = React.useReducer(x => x + 1, 0)[1];

    React.useEffect(() => {
        AccountInfoManager.userUpdatesSignal.subscribe(onUserUpdate);
        return () => AccountInfoManager.userUpdatesSignal.unsubscribe(onUserUpdate);
    }, []);

    function onUserUpdate() {
        forceUpdate();
    }
  
    return (
        <>
            <div className="">

                <div>
                    <h1>Profile</h1>
                    <div className='profile-main-panel'>
                        <BaseProfileInfo success={success} alert={alert}/>
                    </div>
                </div>

            </div>
        </>
    )
}

function BaseProfileInfo({success, alert}) {
    
    async function setNewNick(nickname) {
        const res = await AccountInfoManager.setNewNick(nickname);
        if (res)
            success("Nickname changed successfully");
        else
            alert("Server error - can't change nickname :(");
        return res;
    }

    return (
        <div className="block-panel-main profile-info-panel">
            <EditableLabel valueFunc={AccountInfoManager.getUsername} onSave={setNewNick}/>
            <br/><br/>
            <ProfileStats/>
        </div>
    )
}

function ProfileStats() {
    async function getMyPlace() {
        const place = await AccountInfoManager.getMyPlace();
        if (place === undefined)
            return place;   
        return place > 0 ? place : "N/A";
    }

    async function getMyReward() {
        const myPlace = await AccountInfoManager.getMyPlace();
        const rewards = await Rewards.getRewardsInfoPromise();
        if (myPlace !== undefined && rewards) {
            const reward = rewards.getRewardForPlace(myPlace);
            if (reward)
                return (<center><RewardList rewards={reward}/></center>);
            return "N/A";
        }
        return undefined;
    }
    return (
        <>
            <TitleValueLine title="Points" dataFetcher={AccountInfoManager.getMyPoints}/>
            <TitleValueLine title="Place" dataFetcher={getMyPlace}/>
            <TitleValueLine title="Reward" dataFetcher={getMyReward}/>
        </>
    )
}
