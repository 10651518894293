export class AsyncDict {

    constructor (dataFetcher, refreshTimeout) {
        this.dataFetcher = dataFetcher;
        this.refreshTimeout = refreshTimeout;
        this.data = new Map();
    }

    get(id) {
        if (this.data.has(id)) {
            const item = this.data.get(id);
            const currentTime = new Date();
            if (currentTime - item.lastUpdate > this.refreshTimeout) {
                item.updatePromise(this.dataFetcher(id));
            }
            return item.promise;
        } else {
            const promise = this.dataFetcher(id);
            const item = new AsyncDictItem(promise);
            this.data.set(id, item);
            return promise;
        }
    }
}

class AsyncDictItem {
    constructor(promise) {
        this.updatePromise(promise);
    }

    async updatePromise(promise) {
        this.promise = promise;
        this.lastUpdate = new Date();
    }
}