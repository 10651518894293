export const columns = [
    {
      Header: "",
      accessor: "place",
      width: 30,
      Cell: ({ value, row }) => (
        <div className="leaderboard-table-place">
            <div>
                <span>{value}</span>
            </div>
	    	</div>)
    },
    {
      Header: getHeaderElement("Player"),
      accessor: "username",
      style: {"align-self": "center"},
      width: 80,
    },
    {
      Header: getHeaderElement("Points"),
      accessor: "points",
      style: {"align-self": "center"},
      width: 30,
    },
    {
      Header: getHeaderElement("Days"),
      accessor: "days",
      style: {"align-self": "center"},
      width: 30,
    },
  ];

function getHeaderElement(title) {
  return <div className="default-tile-header iconTitleTile-title">{title}</div>
}