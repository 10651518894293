import RequestQueue from "./requestQueue";

const SERVER_URL = String(process.env.REACT_APP_API_URL)

window.fpRequestQueue = new RequestQueue();

function makeApiServerFetchRaw(uri, method, headers, body) {
    return window.fpRequestQueue.sendRequest(SERVER_URL + uri, {
        method: method,
        headers: headers,
        body: body
    });
}

function makeApiServerFetchFormFromObj(uri, headers, formObj, errorFormat) {
    var formBody = [];
    for (var property in formObj) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(formObj[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return makeApiServerFetchRaw(uri, "POST",
        {...headers, 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        formBody, errorFormat);
}

export function authGetDummyUserData(data) {
    var body = {
        user_id: data.user_id,
        access_token: String(process.env.REACT_APP_PUBLIC_KEY),
    };

    return makeApiServerFetchFormFromObj(
        "/forceprime/starknet/account/dummy/create",
        {},
        body
    );
}

export function apiGetSelfProfileData(token) {
    return makeApiServerFetchRaw(
        "/heroes/profile/self",
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}

export function apiSetUsername(authToken, username) {
    var body = {
        username: username,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/profile/set_username",
        {
            'x-auth-token' : authToken,
        },
        body
    )
}

export function apiGetGameReset(token) {
    return makeApiServerFetchRaw(
        "/heroes/game/reset",
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}

export function apiGetSeasonDiscordCode(token) {
    return makeApiServerFetchRaw(
        "/heroes/season/user_discord_code",
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}

export function apiGetPrizeInfo() {
    return makeApiServerFetchRaw(
        "/heroes/noauth/prize/current_info",
        "GET",
        {},
        null
    )
}

export function apiGetPreviousSeasonInfo(seasonId) {
    var body = {
    };
    return makeApiServerFetchFormFromObj(
        "/heroes/noauth/season/prev?season_id="+seasonId,
        {},
        body
    )
}


export function apiGetLeaderboard(place: int, count: int) {
    return makeApiServerFetchRaw(
        `/heroes/noauth/season/leaderboard/get?place=${place}&count=${count}`,
        "GET",
        {},
        null
    )
}

export function apiGetUserInfoMany(ids) {
    var body = {
        uids: ids.join(",")
    };
    return makeApiServerFetchFormFromObj(
        `/api/public/profile/info_many`,
        {},
        body
    )
}

export function apiGetGlobalSeasonConfig() {
    var body = {
    };
    return makeApiServerFetchFormFromObj(
        "/heroes/noauth/season/global_config",
        {},
        body
    )
}

export function apiGetMapConfig(authToken, mapId) {
    var body = {
        map_id: mapId,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/map/config",
        {
            'x-auth-token' : authToken,
        },
        body
    )
}

export function apiGetMapSeasonInfo(mapId, place: int, count: int) {
    var body = {
    };
    return makeApiServerFetchFormFromObj(
        "/heroes/noauth/map/leaderboard/get?map_id="+mapId,
        {},
        body
    )
}

export function apiGetMapReset(token,mapId) {
    return makeApiServerFetchRaw(
        "/heroes/map/reset?map_id="+mapId,
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}