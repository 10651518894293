import React from 'react'

import {TailSpin} from "react-loader-spinner";
import {delay} from "../../common/utility/delay";

import {Link} from "react-router-dom";

import AccountSignInForm from "../popups/AccountSignInForm";

import { AccountInfoManager } from '../managers/accountInfoManager';
import { AuthManager } from '../managers/authManager';
import { Rewards } from '../managers/rewardsManager';
import IconTitleValue from '../../common/iconTitleValue';
import CountdownLabel from '../../common/countdownLabel';
import { iconPath } from '../../common/paths';
import { SeasonManager } from '../managers/seasonsManager';

export function AccountPanel() {
    const [{isLoading, user}, setState] = React.useState({});
    
    const alert = window.alerts?.alert;

    React.useEffect(() => {
        load();
    }, []);

    React.useEffect(() => {
        AccountInfoManager.userUpdatesSignal.subscribe(onUserUpdate);
        return () => AccountInfoManager.userUpdatesSignal.unsubscribe(onUserUpdate);
        async function onUserUpdate() {
            load();   
        }
    }, []);

    async function load() {
        if (isLoading)
            return;
        
        setState({isLoading: true});

        const authToken = AuthManager.getAuthToken();
        if (authToken) {
            const res = await AccountInfoManager.retrieveUserDataPromise();
            if (res) {
                setState({user: res.user});
            } else {
                alert("Internal server error. Please refresh page.")
                setState({user: null});    
            }
        } else {
            const session = await AuthManager.waitForSession();
            setState({session: session, user: null});
        }
    }

    function onClickLogout() {
        AuthManager.logout();
    }

    const loading = (<span><TailSpin wrapperStyle={{display: "inline"}} wrapperClass="" color={"#fff"} height={15} radius={"2"} /></span>)

    let loginElement = (<div className={"d-flex flex-row-reverse align-items-center h-100"}>
        <div>
            {loading}
        </div>
    </div>)

    if (!isLoading) {
        if (!user) {
            loginElement = (
                    <div className={"d-flex flex-row-reverse align-items-center h-100"}>
                        <div>
                            <AccountSignInForm label="Sign In"/>
                        </div>
                    </div>
                )
        } else {
            loginElement = (
                <div className="d-flex flow-horizontal gap-2">
                    <div className="dropdown">
                        <button className="default-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {user.username}
                        </button>
                        <ul className="dropdown-menu">
                            <Link className="dropdown-item pointer" to="/profile"><img src={iconPath("profile.svg")}/> Profile</Link>
                            <li><hr className="dropdown-divider"/></li>
                            <a className="dropdown-item pointer" href="#" onClick={onClickLogout}><img src={iconPath("logout.svg")}/> Logout</a>
                        </ul>
                    </div>
                </div>
            ) 
        }
    }

    return (
        <div className="account-panel d-flex flex-horizontal">
            <div className="logo-container">
                <Link to="/"><img className="logo-image" src={require('../../static/fp-logo-white.png')}/></Link>
            </div>

            <SeasonInfo/>

            <div className="login-element">
                {loginElement}
            </div>
        </div>
    )
}

export function SeasonInfo() {
    const [seasonInfo, setSeasonInfo] = React.useState(undefined);

    async function loadRewards() {
        const res = await SeasonManager.getCurrentSeasonInfo();
        setSeasonInfo(res);
    }

    loadRewards();

    if (!seasonInfo)
        return <></>

    return (
        <div className="account-panel-info">
                <div className="flex-panel">
                    <IconTitleValue
                        icon="prizePool.svg"
                        title="Reward pool">
                        <div>{seasonInfo.reward}</div>
                    </IconTitleValue>
                    <IconTitleValue 
                        icon="seasonEnd.svg"
                        title="Season end">
                        <CountdownLabel targetDate={seasonInfo.dateEnd * 1000}/>
                    </IconTitleValue>
                </div>
            </div>
    )
}