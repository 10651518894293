import {
  Pagination,
  PaginationItem,
  Stack,
  Box,
} from "@mui/material";

import * as React from "react";

function Paginator({startPage, pages, handleChange, tableIsFull}) {
	const [page, setPage] = React.useState(startPage);

    const onTabChange = (event, value) => {
        setPage(value);
		handleChange(value);
    };
	
	return (
		<Box
			sx={{
				margin: "auto",
				width: "fit-content",
				alignItems: "center",
			}}>

			<Stack spacing={2}>
				<Pagination
					onChange={onTabChange}
					color="secondary"
					page = {page}
					size="large"
					count={pages < 0 ? (tableIsFull ? page + 1: page) : pages}
					renderItem={(item) => (
						<PaginationItem
							{...item}
						/>
					)}
				/>
			</Stack>
		</Box>
	)
}

export default Paginator; 