import React from "react";
import TextProvider from "../../common/textProvider";
import IconTitleValue from "../../common/iconTitleValue";
import MapLeaderboardTable from "../tables/MapLeaderboardTable";
import { useParams } from "react-router-dom";
import { MapsManager } from "../managers/mapsManager";

const MapTopPage = () => {
    
    const params = useParams();
    const mapId = params.map;

    const [map, setMap] = React.useState(undefined);

    async function load() {
        const mapData = await MapsManager.getMapInfo(mapId);
        setMap(mapData);
    }

    function cleanup() {}
    React.useEffect(()=>{ load(); return cleanup; }, []);

    if (!map) return (<>Loading...</>)

    return (
        <>
        <h1>{map.name}</h1>
        <div className="block-panel-main panel-row flex-gap-2 cool-list width100 leaderboard-info-panel">
            <div>
                <h3>At the end of the season, rewards will go to:</h3>
                <ul>
                    <li>Top-20 players according to their rating</li>
                    <li>10 random players</li>
                </ul>
                Please note that you need to earn at least<br/>20 points to have a chance to win a random reward
            </div>
            <div className="tile-grid">
                <IconTitleValue icon="people.svg" title={TextProvider.getText("tile::players")} dataFetcher={() => 1}
                    hint={TextProvider.getText("tile::players::hint")}/>
                <IconTitleValue icon="prizePeople.svg" title={TextProvider.getText("tile::places")} dataFetcher={() => 1}
                    hint={TextProvider.getText("tile::places::hint")}/>
                <IconTitleValue icon="dice.svg" title={TextProvider.getText("tile::randomCount")} dataFetcher={() => 1}
                    hint={TextProvider.getText("tile::randomCount::hint")}/>
                <IconTitleValue icon="pointsMin.svg" title={TextProvider.getText("tile::randomPoints")} dataFetcher={() => 1}
                    hint={TextProvider.getText("tile::randomPoints::hint")}/>
            </div>

            <MapLeaderboardTable mapId={mapId} pageSize={50} needPages={true}/>
        </div>
        </>
    )
};

export default MapTopPage;