import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './screensize.css';
import './games.css';
import './buttons.css';
import './animations.css';
import './common.css';
import './highlighter.css';
import './fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer} from "react-toastify";
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import Forceprime from "./forceprime/Forceprime"
import { AuthManager } from './forceprime/managers/authManager';

const root = ReactDOM.createRoot(document.getElementById('root'));

AuthManager.init();

root.render(
    <CookiesProvider>
        <ToastContainer/>
        <BrowserRouter>
            <Forceprime/>
        </BrowserRouter>
    </CookiesProvider>
);
