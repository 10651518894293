import React from "react";
import { Modal } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import AccountSignInForm from "../popups/AccountSignInForm";
import { AuthManager } from "../managers/authManager";

const images = 
[
    "rehabresort/1.png",
    "rehabresort/2.png",
    "rehabresort/3.png",
    "rehabresort/4.png",
    "rehabresort/5.png",
    "rehabresort/6.png",
    "rehabresort/7.png",
    "rehabresort/8.png",

    "skullcoin/2.jpg",
    "skullcoin/336.jpg",
    "skullcoin/217.jpg",
    "skullcoin/311.jpg",
    "skullcoin/77.jpg",
    "skullcoin/99.jpg",
    "skullcoin/50.jpg",
    "skullcoin/178.jpg",
]

const NewsElement = () => {
    const [infoPopupVisible, showInfoPopup] = React.useState(undefined);

    return (
        <>
            <Marquee>
                <div className="news-element panel-row pointer" onClick={() => showInfoPopup(true)}>
                    <Text/>
                    <ImageList uriList={images}/>
                </div>
            </Marquee>
        </>
    );
};

const Text = ({}) => {
    return (
        <>
            <b style={{marginLeft:"50px"}}>More bonuses!</b>&nbsp;Now for &nbsp;<b>Rehab Resort</b>&nbsp; holders! Check your profile!&nbsp;<b>More to come!</b>
        </>
    )
}

const ImageList = ({uriList}) => {
    return (
        <div>
            {uriList.map( (url, idx) => {
                return <div key={idx} className="news-element-image"> <img src={url}/> </div>
            })}
        </div>
    )
}


export default NewsElement;