export const configuration = {
    name: "Force Prime",
    appIcon: "https://playfp.xyz/android-chrome-192x192.png",
    twitter: "https://twitter.com/ForcePrime_io",
    discord: "https://discord.gg/8SFzxSgbq3",
    docs: "https://fp-heroes.gitbook.io/how-to-play/",
    contactUrl: "https://forms.gle/MBxELR3P6fEkgZmm8",
    sentryUrl: "",
    googleTagId: "G-NKYPVYZRYS",
}


