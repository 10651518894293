import { Link } from 'react-router-dom';
import { delay } from './utility/delay';
import { iconPath } from './paths';


const CoolLink = ({linkTo, text, icon}) => {

  function handleClick() {
     const pos = linkTo.includes("#");
     if (pos >= 0) {
        const path = linkTo.substring(0, pos);
        const hash = linkTo.substring(pos + 1);
        scrollFunc(hash);
     }
  }

  async function scrollFunc(name) {
    await delay(100);
    let e = document.getElementById(name);
    if (e) {
        e.scrollIntoView({
            behavior: 'smooth',
        });
    }
  }

  return (
            <Link className="cool-link" onClick={handleClick} to={linkTo} target={linkTo.startsWith("http") ? "_blank" : ""}>
                <img style={{objectFit:"scale-down", width: "30px", height: "30px"}} src={iconPath(icon)}/>
                <div>{text}</div>
            </Link>
 )
};

export default CoolLink;